import React, { useMemo } from 'react'

import { EmailNotificationLayout } from '../../../components/email-notification'
import SVG from 'react-inlinesvg'
import { formatStatFloatValue } from '../../../components/shared'

const planLimits = [
  '$50.000 Commission',
  '250.000 Records',
  '50.000 Pageviews',
  '3 Users',
  '50 Direct Partners',
]

const planFeatures = [
  'Unified reporting across',
  'Multiple affiliate networks',
  'Full API access Integration with adcertisers',
  'White-label affiliate networks',
  'Google-shopping feed Sub-affiliates',
]

export default function UpgradePlan({ location }) {
  const queryParams = new URLSearchParams(location.search)
  const type = queryParams.get('type')
  const plan = queryParams.get('plan')

  const upgradePlanCard = useMemo(() => {
    if (type === '1') {
      return (
        <div className="email-card email-card-yellow gap-5 p-5">
          <div className="row row-center gap-4">
            <div>
              <SVG
                src="/images/email-notifications/upgrade-plan-box-gray.svg"
                width={11}
                height={13}
              />
              <p className="text-bigger text-bold m-t-10">Freemium</p>
              <p className="text-tiny text-bold">Previous plan</p>
            </div>
            <SVG
              src="/images/email-notifications/arrow.svg"
              width={14}
              height={11}
            />
            <div>
              <SVG
                src="/images/email-notifications/upgrade-plan-box.svg"
                width={11}
                height={13}
              />
              <p className="text-bigger text-bold m-t-10">Starter</p>
              <p className="text-tiny text-bold text-blue">New plan</p>
            </div>
          </div>
          <div>
            <p className="plan-limit-title">Plan limits</p>
            {planLimits.map((item, key) => (
              <div key={key} className="row row-center gap-1">
                <SVG
                  src="/images/email-notifications/upgrade-plan-check.svg"
                  width={14}
                  height={14}
                />
                <p className="upgrade-plan-item-description text-dark">
                  {item}
                </p>
              </div>
            ))}
          </div>
        </div>
      )
    }
    return (
      <div className="email-card p-0">
        <img
          src="/images/email-notifications/upgrade-plan-card-image.png"
          alt="upgrade-card-image"
          className="upgrade-plan-image"
        />
      </div>
    )
  }, [type])

  const newPlanLimits = useMemo(() => {
    if (type === '2') {
      return {
        Records: 250,
        Commission: 50,
        PageViews: 50,
        Users: 3,
      }
    }
    return null
  }, [type])

  return (
    <>
      <EmailNotificationLayout>
        <div className="email-notification-content">
          <h1 className="text-largiestest text-bold text-center text-dark m-t-0">
            Hurrah you have upgraded your plan! 🥳
          </h1>
          {upgradePlanCard}
          <p className="text-center text-smaller text-dark p-x-20">
            <span className="text-boldest">
              Congratulations on your new Plan!
            </span>
            <br />
            Business is booming and you’ve upgraded to &nbsp;
            <span className="text-boldest">{plan}</span>.
          </p>
          <button className="btn btn-xl btn-primary text-bold">
            Go to Subscription page
          </button>
          <div>
            <p className="text-big text-bolder m-b-1">Your new plan features</p>
            {planFeatures.map((item, key) => (
              <div key={key} className="row row-center gap-1">
                <SVG
                  src="/images/email-notifications/upgrade-plan-check.svg"
                  width={14}
                  height={14}
                />
                <p className="upgrade-plan-item-description">{item}</p>
              </div>
            ))}
          </div>
          {newPlanLimits && (
            <>
              <p className="text-big text-bolder text-dark">
                Your new plan limits
              </p>
              <div className="row gap-30">
                {Object.entries(newPlanLimits).map(([key, value], index) => (
                  <div key={index} className="text-primary text-smaller">
                    <p className="m-0">{key}</p>
                    <div className="row row-center gap-1">
                      <SVG
                        src={`/images/email-notifications/upgrade-plan-${key}-icon.svg`}
                        height={16}
                      />
                      <p className="m-y-2">
                        {key === 'Users'
                          ? value
                          : formatStatFloatValue(value, 3)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </EmailNotificationLayout>
    </>
  )
}
